import { BuildingSelector } from 'components/building-selector';
import { PageHeader } from 'components/page-header';
import { Skeleton } from 'components/skeleton/skeleton';
import { useSettingsPage } from 'pages/settings-page/hooks/use-settings-page.hook';
import { NotificationPreference } from 'pages/settings-page/notification-preference';
import { SettingsList, StyledWrapper, Container } from 'pages/settings-page/styles';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ACTION_STATUSES } from 'shared/consts';
import { selectGetUserBuildingsStatus, selectUserBuildings } from 'store/building/selectors';
import {
  selectGetNotificationPreferencesListStatus,
  selectNotificationPreferences,
} from 'store/notification-preferences/selectors';

export const SettingsPage = (): JSX.Element => {
  const intl = useIntl();
  const pageHeaderRef = useRef<HTMLDivElement>();
  useSettingsPage();
  const notificationPreferences = useSelector(selectNotificationPreferences);
  const getUserBuildingsStatus = useSelector(selectGetUserBuildingsStatus);
  const userBuildings = useSelector(selectUserBuildings);
  const getNotificationPreferencesListStatus = useSelector(selectGetNotificationPreferencesListStatus);
  const isInitialPageLoading = (getUserBuildingsStatus === ACTION_STATUSES.PENDING
    || getNotificationPreferencesListStatus === ACTION_STATUSES.PENDING)
    && (userBuildings.length === 0 || notificationPreferences.length === 0);

  return (
    <StyledWrapper>
      <PageHeader pageHeaderRef={pageHeaderRef} title={intl.formatMessage({ id: 'push_notification_settings' })} />
      <Container data-testid="settings-page">
        {isInitialPageLoading ? (
          <Skeleton boneSizePx={38} boneNumber={4} withWrapper />
        ) : (
          <>
            <BuildingSelector />
            {getNotificationPreferencesListStatus === ACTION_STATUSES.PENDING ? (
              <Skeleton boneSizePx={38} />
            ) : (
              <SettingsList data-testid="settings-list">
                {notificationPreferences.map((notificationPreference) => (
                  <NotificationPreference
                    key={notificationPreference.uuid}
                    notificationPreference={notificationPreference}
                  />
                ))}
              </SettingsList>
            )}
          </>
        )}
      </Container>
    </StyledWrapper>
  );
};
