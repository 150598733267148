import * as actions from './actions';

import { Observable, concat, of } from 'rxjs';
import {
  catchError, filter, map, switchMap, withLatestFrom,
} from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { getErrorCode } from 'store/utils/get-error-code.util';
import { getNotificationPreferencesList } from 'store/notification-preferences/actions';

export const getBuildingEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.getBuilding.request)),
  withLatestFrom(state$),
  switchMap(
    ([{ payload }, state]): Observable<RootAction> => apiClient(state)
      .getBuilding(payload)
      .pipe(
        map((xhrPayload) => actions.getBuilding.success(xhrPayload.response.data.building)),
        catchError((error) => of(actions.getBuilding.failure({ error, errorCode: getErrorCode(error) }))),
      ),
  ),
);

export const getUserBuildingsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.getUserBuildings.request)),
  withLatestFrom(state$),
  switchMap(
    ([, state]): Observable<RootAction> => apiClient(state)
      .getUserBuildings()
      .pipe(
        switchMap((xhrPayload) => concat(
          of(actions.getUserBuildings.success(xhrPayload.response.data.buildings)),
          of(getNotificationPreferencesList.request({ buildingUuid: state.building?.building?.uuid })),
        )),
        catchError((error) => of(actions.getUserBuildings.failure({ error, errorCode: getErrorCode(error) }))),
      ),
  ),
);
