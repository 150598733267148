import React from 'react';
import { faEnvelopeOpen } from '@fortawesome/pro-solid-svg-icons';
import {
  DropdownContainer,
  DropdownButton,
  DropdownContent,
  StyledIcon,
  ButtonText,
  StyledButtonIcon,
} from './styles';
import { useIntl } from 'react-intl';
import { useAllReadButton } from './hooks/use-all-read-button.hook';

export const AllReadButton = () => {
  const intl = useIntl();
  const {
    isDropdownOpen, dropdownRef, toggleDropdownVisibility, handleButtonClick,
  } = useAllReadButton();

  return (
    <DropdownContainer ref={dropdownRef}>
      <StyledIcon data-testid="all-read-icon" icon={faEnvelopeOpen} onClick={toggleDropdownVisibility} />
      {isDropdownOpen && (
        <DropdownContent>
          <DropdownButton onClick={handleButtonClick}>
            <StyledButtonIcon data-testid="all-read-icon" icon={faEnvelopeOpen} />
            <ButtonText>{intl.formatMessage({ id: 'markAllAsRead' })}</ButtonText>
          </DropdownButton>
        </DropdownContent>
      )}
    </DropdownContainer>
  );
};
