import { NotificationCategoryEnum } from 'store/notifications/types';

export enum NOTIFICATION_VARIANT {
  READ = 'READ',
  UNREAD = 'UNREAD',
  INACTIVE = 'INACTIVE',
}

export const SWIPE_THRESHOLD = 69;
export const DELETE_SWIPE_THRESHOLD = 138;
export const TOUCH_MOVEMENT_THRESHOLD = 35;
export const SWIPE_THRESHOLD_DIVISOR = 3;
export const DELETE_SWIPE_THRESHOLD_DIVISOR = 6;
export const SHIFT_DRAWER_OPENED = 0;

export enum MOVEMENT_DIRECTION {
  LEFT = 'left',
  RIGHT = 'right',
  UNMOVED = 'unmoved',
}

export const categoryToVerticalMap = new Map<NotificationCategoryEnum, string>([
  [NotificationCategoryEnum.CONTENT, NotificationCategoryEnum.CONTENT],
  [NotificationCategoryEnum.INFORMATIONAL_UPDATES, NotificationCategoryEnum.INFORMATIONAL_UPDATES],
  [NotificationCategoryEnum.PACKAGE_MANAGEMENT_INCOMING, 'PACKAGE_MANAGEMENT'],
  [NotificationCategoryEnum.PACKAGE_MANAGEMENT_OUTGOING, 'PACKAGE_MANAGEMENT'],
  [NotificationCategoryEnum.RESOURCE_BOOKING_STATUS_UPDATES, 'RESOURCE_BOOKING'],
  [NotificationCategoryEnum.VISITOR_REGISTRATION_VISIT_UPDATES, 'VISITOR_REGISTRATION'],
  [NotificationCategoryEnum.VISITOR_REGISTRATION_VISITOR_KEY_UPDATES, 'VISITOR_REGISTRATION'],
  [NotificationCategoryEnum.SERVICE_REQUEST_UPDATES, 'SERVICE_REQUEST'],
  [NotificationCategoryEnum.PARKING_UPDATES, 'PARKING'],
]);
export const SCROLL_MOVEMENT_THRESHOLD = 10;
