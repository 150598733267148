import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledWrapper = styled.div<{ pageHeaderHeight: number }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ pageHeaderHeight }) => `calc(100vh - ${pageHeaderHeight}px)`};
`;
