import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { BuildingState } from 'store/building/types';
import { ACTION_STATUSES } from 'shared/consts';

export const INITIAL_STATE: BuildingState = {
  building: null,
  userBuildings: null,
  selectedBuilding: null,
  getBuilding: {
    status: null,
    error: null,
  },
  getUserBuildings: {
    status: null,
    error: null,
  },
};

const getBuildingRequestHandler = (state: BuildingState): BuildingState => ({
  ...state,
  getBuilding: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getBuildingSuccessHandler = (
  state: BuildingState,
  { payload }: ActionType<typeof actions.getBuilding.success>,
): BuildingState => ({
  ...state,
  building: payload,
  selectedBuilding: {
    label: payload.name,
    value: payload.uuid,
  },
  getBuilding: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getBuildingFailureHandler = (
  state: BuildingState,
  { payload }: ActionType<typeof actions.getBuilding.failure>,
): BuildingState => ({
  ...state,
  getBuilding: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const getUserBuildingsRequestHandler = (state: BuildingState): BuildingState => ({
  ...state,
  getUserBuildings: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getUserBuildingsSuccessHandler = (
  state: BuildingState,
  { payload }: ActionType<typeof actions.getUserBuildings.success>,
): BuildingState => ({
  ...state,
  userBuildings: payload,
  getUserBuildings: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getUserBuildingsFailureHandler = (
  state: BuildingState,
  { payload }: ActionType<typeof actions.getUserBuildings.failure>,
): BuildingState => ({
  ...state,
  getUserBuildings: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const setSelectedBuildingHandler = (
  state: BuildingState,
  { payload }: ActionType<typeof actions.setSelectedBuilding>,
): BuildingState => ({
  ...state,
  selectedBuilding: payload,
});

const BuildingReducer = createReducer(INITIAL_STATE)
  .handleAction(actions.getBuilding.request, getBuildingRequestHandler)
  .handleAction(actions.getBuilding.success, getBuildingSuccessHandler)
  .handleAction(actions.getBuilding.failure, getBuildingFailureHandler)
  .handleAction(actions.getUserBuildings.request, getUserBuildingsRequestHandler)
  .handleAction(actions.getUserBuildings.success, getUserBuildingsSuccessHandler)
  .handleAction(actions.getUserBuildings.failure, getUserBuildingsFailureHandler)
  .handleAction(actions.setSelectedBuilding, setSelectedBuildingHandler);

export default BuildingReducer;
