/* eslint-disable max-len */
import * as userActions from './actions';

import {
  catchError,
  filter,
  map,
  mapTo,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { AjaxError } from 'rxjs/ajax';
import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { UnauthorizedError } from 'store/user/errors';
import { isActionOf } from 'typesafe-actions';
import { concat, of } from 'rxjs';
import qs from 'qs';
import { replace } from 'store/router/actions';
import { getErrorCode } from 'store/utils/get-error-code.util';
import { ERROR_DEFAULT } from 'shared/consts/error-codes';
import { getBuildingTheme } from 'store/theme/actions';
import { getBuilding } from 'store/building/actions';

const UNAUTHORIZED_ERROR_CODE = 401;
const NOT_FOUND_ERROR_CODE = 404;
const BAD_REQUEST_ERROR_CODE = 400;
const INTERNAL_SERVER_ERROR_CODE = 500;

const isUnauthorizedError = (error: unknown): boolean => (
  error instanceof AjaxError && error.status >= BAD_REQUEST_ERROR_CODE && error.status < INTERNAL_SERVER_ERROR_CODE
);

export const externalLogin: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(userActions.externalLogin.request)),
  withLatestFrom(state$),
  switchMap(([{ payload }, state]) => {
    const { authToken, apiUrl, buildingUuid } = payload;

    return apiClient(state, apiUrl, authToken)
      .getCurrentUser()
      .pipe(
        switchMap((xhrPayload) => concat(of(userActions.externalLogin.success({ user: xhrPayload.response, apiUrl, token: authToken })), of(getBuildingTheme.request(buildingUuid)), of(getBuilding.request(buildingUuid)))),
        catchError((error: Error) => {
          if (isUnauthorizedError(error)) {
            return of(userActions.externalLogin.failure({ error: new UnauthorizedError(), errorCode: ERROR_DEFAULT }));
          }

          return of(userActions.externalLogin.failure({ error, errorCode: getErrorCode(error) }));
        }),
      );
  }),
);

export const externalLoginRedirect: Epic<RootAction, RootAction, RootState> = (action$) => action$.pipe(
  filter(isActionOf(userActions.externalLogin.success)),
  map(() => {
    const search: string = window.location?.search ?? '';
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { authToken, apiUrl, ...restSearch } = qs.parse(search.replace(/^\?/, ''));
    const newSearchString = qs.stringify(restSearch);
    const path = window.location?.pathname ?? '/'; // ? window.location

    return replace(newSearchString ? `${path}?${newSearchString}` : path);
  }),
);

export const externalLoginFailureRedirect: Epic<RootAction, RootAction, RootState> = (action$) => (
  action$.pipe(filter(isActionOf(userActions.externalLogin.failure)), mapTo(replace('/')))
);

export const getCurrentUser: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(userActions.getCurrentUser.request)),
  withLatestFrom(state$),
  switchMap(([, state]) => apiClient(state)
    .getCurrentUser()
    .pipe(
      map((xhrPayload) => userActions.getCurrentUser.success({ user: xhrPayload.response })),
      catchError((error: Error) => {
        if (
          error instanceof AjaxError
              && (error.status === UNAUTHORIZED_ERROR_CODE || error.status === NOT_FOUND_ERROR_CODE)
        ) {
          return of(userActions.getCurrentUser.failure({ error: new UnauthorizedError(), errorCode: ERROR_DEFAULT }));
        }

        return of(userActions.getCurrentUser.failure({ error, errorCode: getErrorCode(error) }));
      }),
    )),
);
