/* eslint-disable max-len */
import { RootState } from '../reducer';
import { createSelector } from 'reselect';
import { NotificationPreferencesState } from 'store/notification-preferences/types';

export const notificationPreferencesStateSelector = (state: RootState): NotificationPreferencesState => state.notificationPreferences;

export const selectNotificationPreferences = createSelector(
  notificationPreferencesStateSelector,
  (notificationsState) => notificationsState.notificationPreferences || [],
);

export const selectGetNotificationPreferencesListStatus = createSelector(
  notificationPreferencesStateSelector,
  (notificationsState) => notificationsState.getNotificationPreferencesList.status,
);

export const selectTotalNotificationPreferences = createSelector(
  notificationPreferencesStateSelector,
  (notificationsState) => notificationsState.totalNotificationPreferences || 0,
);

export const selectUpdateNotificationPreferenceStatus = createSelector(
  notificationPreferencesStateSelector,
  (notificationsState) => notificationsState.updateNotificationPreference.status,
);
