import * as actions from './actions';

import { ActionType, createReducer } from 'typesafe-actions';

import { ACTION_STATUSES } from 'shared/consts';
import { ALL_NOTIFICATIONS, NotificationsState, PATCH_NOTIFICATION_STATUS } from 'store/notifications/types';
import { PayloadError } from 'store/notifications/errors/payload-error';

export const INITIAL_STATE: NotificationsState = {
  notifications: null,
  totalNotifications: null,
  patchNotificationUuid: null,
  deleteNotificationUuid: null,
  getNotificationList: {
    status: null,
    error: null,
  },
  getInfiniteNotifications: {
    status: null,
    error: null,
  },
  patchNotification: {
    status: null,
    error: null,
  },
  deleteNotificationForUser: {
    status: null,
    error: null,
  },
};

const getNotificationListRequestHandler = (state: NotificationsState): NotificationsState => ({
  ...state,
  getNotificationList: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getNotificationListSuccessHandler = (
  state: NotificationsState,
  { payload }: ActionType<typeof actions.getNotificationList.success>,
): NotificationsState => {
  if (!payload) {
    return {
      ...state,
      getNotificationList: {
        error: new PayloadError('Payload is null or undefined'),
        status: ACTION_STATUSES.REJECTED,
      },
    };
  }

  return {
    ...state,
    notifications: payload.data,
    totalNotifications: payload.total,
    getNotificationList: {
      error: null,
      status: ACTION_STATUSES.FULFILLED,
    },
  };
};

const getNotificationListFailureHandler = (
  state: NotificationsState,
  { payload }: ActionType<typeof actions.getNotificationList.failure>,
): NotificationsState => ({
  ...state,
  getNotificationList: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const getInfiniteNotificationsRequestHandler = (state: NotificationsState): NotificationsState => ({
  ...state,
  getInfiniteNotifications: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getInfiniteNotificationsSuccessHandler = (
  state: NotificationsState,
  { payload }: ActionType<typeof actions.getInfiniteNotifications.success>,
): NotificationsState => ({
  ...state,
  notifications: [...(state.notifications || []), ...payload],
  getInfiniteNotifications: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getInfiniteNotificationsFailureHandler = (
  state: NotificationsState,
  { payload }: ActionType<typeof actions.getInfiniteNotifications.failure>,
): NotificationsState => ({
  ...state,
  getInfiniteNotifications: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const patchNotificationRequestAndRedirectHandler = (
  state: NotificationsState,
  { payload }: ActionType<typeof actions.patchNotificationAndRedirect.request>,
): NotificationsState => {
  const isPatchRequired = payload.drawerClick
    || (!payload.drawerClick && payload.inAppReadStatus === PATCH_NOTIFICATION_STATUS.READ);

  return isPatchRequired ? ({
    ...state,
    patchNotificationUuid: payload.notificationUuid,
    patchNotification: {
      error: null,
      status: ACTION_STATUSES.PENDING,
    },
  }) : ({ ...state });
};

const patchNotificationSuccessAndRedirectHandler = (
  state: NotificationsState,
  { payload }: ActionType<typeof actions.patchNotificationAndRedirect.success>,
): NotificationsState => ({
  ...state,
  notifications: state.notifications?.map((notification) => {
    if (notification.uuid === payload.notificationUuid || payload.notificationUuid === ALL_NOTIFICATIONS) {
      return {
        ...notification,
        in_app_read_at: payload.inAppReadStatus === PATCH_NOTIFICATION_STATUS.READ ? new Date() : null,
      };
    }

    return notification;
  }),
  patchNotificationUuid: null,
  patchNotification: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const patchNotificationFailureAndRedirectHandler = (
  state: NotificationsState,
  { payload }: ActionType<typeof actions.patchNotificationAndRedirect.failure>,
): NotificationsState => ({
  ...state,
  patchNotificationUuid: null,
  patchNotification: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const deleteNotificationForUserRequestHandler = (
  state: NotificationsState,
  { payload }: ActionType<typeof actions.deleteNotificationForUser.request>,
): NotificationsState => ({
  ...state,
  deleteNotificationUuid: payload.notificationUuid,
  deleteNotificationForUser: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const deleteNotificationForUserSuccessHandler = (
  state: NotificationsState,
  { payload }: ActionType<typeof actions.deleteNotificationForUser.success>,
): NotificationsState => ({
  ...state,
  notifications: state.notifications?.filter((notification) => notification.uuid !== payload.notificationUuid),
  deleteNotificationForUser: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const deleteNotificationForUserFailureHandler = (
  state: NotificationsState,
  { payload }: ActionType<typeof actions.deleteNotificationForUser.failure>,
): NotificationsState => ({
  ...state,
  deleteNotificationUuid: null,
  deleteNotificationForUser: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const resetDeleteNotificationHandler = (state: NotificationsState): NotificationsState => ({
  ...state,
  deleteNotificationUuid: null,
  deleteNotificationForUser: {
    error: null,
    status: null,
  },
});

const resetPatchNotificationHandler = (state: NotificationsState): NotificationsState => ({
  ...state,
  patchNotificationUuid: null,
  patchNotification: {
    error: null,
    status: null,
  },
});

const NotificationReducer = createReducer(INITIAL_STATE)
  .handleAction(actions.getNotificationList.request, getNotificationListRequestHandler)
  .handleAction(actions.getNotificationList.success, getNotificationListSuccessHandler)
  .handleAction(actions.getNotificationList.failure, getNotificationListFailureHandler)
  .handleAction(actions.getInfiniteNotifications.request, getInfiniteNotificationsRequestHandler)
  .handleAction(actions.getInfiniteNotifications.success, getInfiniteNotificationsSuccessHandler)
  .handleAction(actions.getInfiniteNotifications.failure, getInfiniteNotificationsFailureHandler)
  .handleAction(actions.patchNotificationAndRedirect.request, patchNotificationRequestAndRedirectHandler)
  .handleAction(actions.patchNotificationAndRedirect.success, patchNotificationSuccessAndRedirectHandler)
  .handleAction(actions.patchNotificationAndRedirect.failure, patchNotificationFailureAndRedirectHandler)
  .handleAction(actions.deleteNotificationForUser.request, deleteNotificationForUserRequestHandler)
  .handleAction(actions.deleteNotificationForUser.success, deleteNotificationForUserSuccessHandler)
  .handleAction(actions.deleteNotificationForUser.failure, deleteNotificationForUserFailureHandler)
  .handleAction(actions.resetDeleteNotification, resetDeleteNotificationHandler)
  .handleAction(actions.resetPatchNotification, resetPatchNotificationHandler);

export default NotificationReducer;
