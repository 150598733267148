import { ROOT_PATH, SETTINGS_PATH } from 'shared/consts';
import { Route, Routes } from 'react-router-dom';

import { AppContentWrapper } from 'components/app-content-wrapper/app-content-wrapper';
import { AppRoot } from 'components/app-root';
import { DefaultRedirect } from './default-redirect';
import { ErrorPage } from 'pages/error';
import { NotificationsListPage } from 'pages/notifications-list-page';
import { SettingsPage } from 'pages/settings-page';

import React from 'react';

export const Router: React.FC = (): JSX.Element => (
  <AppContentWrapper>
    <Routes>
      <Route path={ROOT_PATH} element={<NotificationsListPage />} />
      <Route path={SETTINGS_PATH} element={<SettingsPage />} />
      <Route path="/" element={<AppRoot />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route element={<DefaultRedirect />} />
    </Routes>
  </AppContentWrapper>
);
