import { StateType } from 'typesafe-actions';
import altBuildingsReducer from 'store/alt-buildings/reducer';
import BuildingReducer from 'store/building/reducer';
import { combineReducers } from 'redux';
import configReducer from 'store/config/reducer';
import errorsReducer from 'store/errors/reducer';
import themeReducer from 'store/theme/reducer';
import userReducer from 'store/user/reducer';
import uiMetadataReducer from 'store/ui-metadata/reducer';
import notificationReducer from 'store/notifications/reducer';
import notificationPreferencesReducer from 'store/notification-preferences/reducer';

const rootReducer = combineReducers({
  config: configReducer,
  user: userReducer,
  theme: themeReducer,
  errors: errorsReducer,
  altBuildings: altBuildingsReducer,
  building: BuildingReducer,
  uiMetadata: uiMetadataReducer,
  notifications: notificationReducer,
  notificationPreferences: notificationPreferencesReducer,
});

export type RootState = StateType<typeof rootReducer>;

export default rootReducer;
