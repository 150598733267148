import React from 'react';
import { ToastMessageContainer, StyledToastMessage } from './styles';
import { useIntl } from 'react-intl';

interface TrackingNumberToastProps {
  toggleToastVisibility?: VoidFunction;
}

export const ErrorToastMessage = ({ toggleToastVisibility }: TrackingNumberToastProps): JSX.Element => {
  const intl = useIntl();

  return (
    <ToastMessageContainer data-testid="toast-message-container" data-cy="toast-message-container">
      <StyledToastMessage
        icon={null}
        text={intl.formatMessage({ id: 'notifications.toast_error' })}
        onClose={toggleToastVisibility}
      />
    </ToastMessageContainer>
  );
};
