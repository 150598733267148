import React, { useCallback } from 'react';

import {
  Header, StyledIcon, Title, RightPane,
} from 'components/page-header/styles';
import { faGear } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { push } from 'store/router/actions';
import { useLocation } from 'react-router-dom';
import { SETTINGS_PATH, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import { track } from '@hqo/web-tracking';
import { AllReadButton } from '../all-read-button';

interface PageHeaderProps {
  title: string;
  pageHeaderRef: React.MutableRefObject<HTMLDivElement>;
}

export const PageHeader = ({ title, pageHeaderRef }: PageHeaderProps): JSX.Element => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isSettingsPage = pathname.includes(SETTINGS_PATH);

  const handleSettingsIconClick = useCallback(() => {
    dispatch(push(SETTINGS_PATH));
    track(
      TRACK_EVENT_NAMES.NOTIF_LIST_PREFERENCES_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
      },
      { sendToHqoTracking: true },
    );
  }, [dispatch]);

  return (
    <Header data-testid="header" ref={pageHeaderRef}>
      <Title data-testid="header-title">{title}</Title>
      {!isSettingsPage && (
        <RightPane>
          <AllReadButton />
          <StyledIcon data-testid="settings-icon" icon={faGear} onClick={handleSettingsIconClick} />
        </RightPane>
      )}
    </Header>
  );
};
