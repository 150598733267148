/* eslint-disable max-len */
import { FailureActionPayload } from 'store/errors/types';
import { GetNotificationPreferencesList, PaginatedNotificationPreferencesListRo, UpdateNotificationPreference } from 'store/notification-preferences/types';
import { createAsyncAction } from 'typesafe-actions';

export const getNotificationPreferencesList = createAsyncAction(
  'GET_NOTIFICATION_PREFERENCES_LIST_REQUEST',
  'GET_NOTIFICATION_PREFERENCES_LIST_SUCCESS',
  'GET_NOTIFICATION_PREFERENCES_LIST_FAILURE',
)<GetNotificationPreferencesList, PaginatedNotificationPreferencesListRo, FailureActionPayload>();

export const updateNotificationPreference = createAsyncAction(
  'UPDATE_NOTIFICATION_PREFERENCES_REQUEST',
  'UPDATE_NOTIFICATION_PREFERENCES_SUCCESS',
  'UPDATE_NOTIFICATION_PREFERENCES_FAILURE',
)<UpdateNotificationPreference, UpdateNotificationPreference, FailureActionPayload>();
