import { BuildingState, Building } from 'store/building/types';
import { RootState } from '../reducer';
import { createSelector } from 'reselect';

export const buildingStateSelector = (state: RootState): BuildingState => state.building;

export const selectBuilding = createSelector(buildingStateSelector, (buildingState) => buildingState.building);

export const selectBuildingStatus = createSelector(
  buildingStateSelector,
  (buildingState) => buildingState.getBuilding.status,
);

export const selectBuildingTimeZone = createSelector(
  selectBuilding,
  (building: Building | null): string | null => building?.timezone ?? null,
);

export const selectBuildingLocale = createSelector(
  selectBuilding,
  (building: Building | null): string | null => building?.locale ?? null,
);

export const selectUserBuildings = createSelector(
  buildingStateSelector,
  (buildingState) => buildingState.userBuildings || [],
);

export const selectGetUserBuildingsStatus = createSelector(
  buildingStateSelector,
  (buildingState) => buildingState.getUserBuildings.status,
);

export const selectUserBuildingsDropdownItems = createSelector(
  selectUserBuildings,
  (userBuildings) => userBuildings.map(({ name, uuid }) => ({ label: name, value: uuid })),
);

export const selectBuildingName = createSelector(
  selectBuilding,
  (building: Building | null): string | null => building?.name ?? null,
);

export const selectSelectedBuilding = createSelector(
  buildingStateSelector,
  (buildingState) => buildingState.selectedBuilding,
);
