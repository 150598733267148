import React, { useEffect, useRef, useState } from 'react';
import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hooks';
import { useDispatch } from 'react-redux';
import { getNotificationList } from 'store/notifications/actions';
import { ChannelEnum } from '@hqo/notifications-service-client/dist/notification-client/enums/channel.enum';
import { HEADER_BUTTON_MODE } from '@hqo/hqo-miniapp-client-sdk/dist/sdk/types/sdk-message.types';
import { NOTIFICATION_LIST_LIMIT, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import { track } from '@hqo/web-tracking';

interface UseNotificationsListPageReturnValues {
  pageHeaderRef: React.MutableRefObject<HTMLDivElement>;
  pageHeaderHeight: number;
}

export const useNotificationsListPage = (): UseNotificationsListPageReturnValues => {
  const dispatch = useDispatch();
  const client = useMiniappSdk();
  const pageHeaderRef = useRef<HTMLDivElement>();
  const [pageHeaderHeight, setPageHeaderHeight] = useState(0);

  useEffect(() => {
    if (pageHeaderRef?.current && !pageHeaderHeight) {
      setPageHeaderHeight(pageHeaderRef.current.clientHeight);
    }
  }, [pageHeaderRef?.current, pageHeaderHeight]);

  useEffect(() => {
    client?.header.setHeader('', HEADER_BUTTON_MODE.CLOSE);
    client?.header.showHeader();
  }, []);

  useEffect(() => {
    track(
      TRACK_EVENT_NAMES.NOTIF_LIST_IMPRESSION,
      {
        type: TRACK_EVENT_TYPES.IMPRESSION,
      },
      { sendToHqoTracking: true },
    );
  }, []);

  useEffect(() => {
    dispatch(
      getNotificationList.request({
        params: { channels: [ChannelEnum.IN_APP], limit: NOTIFICATION_LIST_LIMIT },
      }),
    );
  }, []);

  return { pageHeaderRef, pageHeaderHeight };
};
