import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownContent = styled.div`
  position: absolute;
  top: 100%;
  width: 165px;
  right: 0;
  margin-top: 2px;
  background-color: ${({ theme }) => theme.colors.palette.sharedWhite};
  box-shadow: ${({ theme }) => theme.shadows.depth_3};
  border-radius: 4px;
  z-index: 1;
`;

export const DropdownButton = styled.button`
  display: flex;
  justify-content: space-around;
  gap: 4px;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px;
  border: none;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.palette.sharedWhite};

  &:hover {
    background-color: ${({ theme }) => theme.colors.$sharedGreyLight};
  }
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.$sharedGreyDark};
  cursor: pointer;
`;

export const StyledButtonIcon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
  color: ${({ theme }) => theme.colors.$sharedGreyDark};
`;

export const ButtonText = styled.div`
  color: ${({ theme }) => theme.colors.fontColor};
  font: 400 14px/20px ${({ theme }) => theme.fonts.join()};
`;
