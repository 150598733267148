import { ACTION_STATUSES } from 'shared/consts';
import { NotificationPreferenceRO, NotificationPreferencesState } from 'store/notification-preferences/types';
import { getNotificationPreferenceChannelField } from 'store/utils/get-channel-field.util';
import { ActionType, createReducer } from 'typesafe-actions';

/* eslint-disable max-len */
import * as actions from './actions';

export const INITIAL_STATE: NotificationPreferencesState = {
  notificationPreferences: null,
  totalNotificationPreferences: null,
  updateNotificationPreference: {
    status: null,
    error: null,
  },
  getNotificationPreferencesList: {
    status: null,
    error: null,
  },

};

const getNotificationPreferencesListRequestHandler = (state: NotificationPreferencesState): NotificationPreferencesState => ({
  ...state,
  getNotificationPreferencesList: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

const getNotificationPreferencesListSuccessHandler = (
  state: NotificationPreferencesState,
  { payload }: ActionType<typeof actions.getNotificationPreferencesList.success>,
): NotificationPreferencesState => ({
  ...state,
  notificationPreferences: payload.data,
  totalNotificationPreferences: payload.total,
  getNotificationPreferencesList: {
    error: null,
    status: ACTION_STATUSES.FULFILLED,
  },
});

const getNotificationPreferencesListFailureHandler = (
  state: NotificationPreferencesState,
  { payload }: ActionType<typeof actions.getNotificationPreferencesList.failure>,
): NotificationPreferencesState => ({
  ...state,
  getNotificationPreferencesList: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const updateNotificationPreferenceRequestHandler = (state: NotificationPreferencesState): NotificationPreferencesState => ({
  ...state,
  updateNotificationPreference: {
    error: null,
    status: ACTION_STATUSES.PENDING,
  },
});

/**
 * Iterates through the current list of notification preferences and updates the enabled status of
 * the notification preference that matches the one updated. This prevents us from having to make
 * another API call to get the updated notification preference list.
*/
const updateNotificationPreferenceSuccessHandler = (
  state: NotificationPreferencesState,
  { payload }: ActionType<typeof actions.updateNotificationPreference.success>,
): NotificationPreferencesState => {
  const notificationPreferences: NotificationPreferenceRO[] = state.notificationPreferences?.map((preference) => {
    if (preference.building_uuid !== payload.buildingUuid || preference.category_uuid !== payload.notificationCategoryUuid) {
      return preference;
    }

    const channelField = getNotificationPreferenceChannelField(payload.channel);

    return {
      ...preference,
      [channelField]: payload.enabled,
    };
  });

  return {
    ...state,
    notificationPreferences,
    updateNotificationPreference: {
      error: null,
      status: ACTION_STATUSES.FULFILLED,
    },
  };
};

const updateNotificationPreferenceFailureHandler = (
  state: NotificationPreferencesState,
  { payload }: ActionType<typeof actions.updateNotificationPreference.failure>,
): NotificationPreferencesState => ({
  ...state,
  updateNotificationPreference: {
    error: payload.error,
    status: ACTION_STATUSES.REJECTED,
  },
});

const NotificationPreferencesReducer = createReducer(INITIAL_STATE)
  .handleAction(actions.getNotificationPreferencesList.request, getNotificationPreferencesListRequestHandler)
  .handleAction(actions.getNotificationPreferencesList.success, getNotificationPreferencesListSuccessHandler)
  .handleAction(actions.getNotificationPreferencesList.failure, getNotificationPreferencesListFailureHandler)
  .handleAction(actions.updateNotificationPreference.request, updateNotificationPreferenceRequestHandler)
  .handleAction(actions.updateNotificationPreference.success, updateNotificationPreferenceSuccessHandler)
  .handleAction(actions.updateNotificationPreference.failure, updateNotificationPreferenceFailureHandler);

export default NotificationPreferencesReducer;
