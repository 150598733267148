import { Building, SelectedBuilding } from 'store/building/types';
import { FailureActionPayload } from 'store/errors/types';
import { createAsyncAction, createAction } from 'typesafe-actions';

export const getBuilding = createAsyncAction('GET_BUILDING_REQUEST', 'GET_BUILDING_SUCCESS', 'GET_BUILDING_FAILURE')<
  string,
  Partial<Building>,
  FailureActionPayload
>();

export const getUserBuildings = createAsyncAction(
  'GET_USER_BUILDINGS_REQUEST',
  'GET_USER_BUILDINGS_SUCCESS',
  'GET_USER_BUILDINGS_FAILURE',
)<void, Array<Building>, FailureActionPayload>();

export const setSelectedBuilding = createAction('SET_SELECTED_BUILDING')<SelectedBuilding | null>();
