import { useDispatch } from 'react-redux';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { patchNotificationAndRedirect } from 'store/notifications/actions';
import { ALL_NOTIFICATIONS, PATCH_NOTIFICATION_STATUS } from 'store/notifications/types';

interface UseAllReadButtonReturnValues {
  dropdownRef: React.MutableRefObject<HTMLDivElement>;
  isDropdownOpen: boolean;
  toggleDropdownVisibility: VoidFunction;
  handleButtonClick: VoidFunction;
}

export const useAllReadButton = (): UseAllReadButtonReturnValues => {
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>();

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdownVisibility = useCallback(() => {
    setIsDropdownOpen((prevState) => !prevState);
  }, []);

  const handleButtonClick = useCallback(() => {
    dispatch(
      patchNotificationAndRedirect.request({
        notificationUuid: ALL_NOTIFICATIONS,
        inAppReadStatus: PATCH_NOTIFICATION_STATUS.READ,
      }),
    );
    toggleDropdownVisibility();
  }, []);

  return {
    isDropdownOpen, dropdownRef, toggleDropdownVisibility, handleButtonClick,
  };
};
