import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SIZE_VARIANTS } from 'shared/consts';
import { NOTIFICATION_VARIANT, SWIPE_THRESHOLD } from './constants';

interface CircleProps {
  size: SIZE_VARIANTS;
  variant?: NOTIFICATION_VARIANT;
}

const circleDimensions: Record<NonNullable<CircleProps['size']>, ReturnType<typeof css>> = {
  small: css`
    width: 20px;
    height: 20px;
  `,
  normal: css`
    width: 36px;
    height: 36px;
  `,
};

export const Title = styled.div<{ variant: NOTIFICATION_VARIANT }>`
  font: ${({ variant }) => (variant === NOTIFICATION_VARIANT.UNREAD ? 800 : 500)} 16px/16px
    ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const Message = styled.div`
  font: 400 15px/20px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
`;

export const MessageInfo = styled.div`
  font: 400 12px/12px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 80%;
`;

export const NotificationIconBlock = styled.div`
  display: flex;
`;
export const ContentBlock = styled.div<{ withBorder: boolean, hasCursorPointer: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme, withBorder }) => (
    !withBorder ? `1px solid ${theme.colors.palette.systemGreyMedium};` : 'none;'
  )}
  flex-grow: 1;
  padding-bottom: 16px;
  margin-left: 16px;
  cursor: ${({ hasCursorPointer }) => (
    hasCursorPointer ? 'pointer' : 'initial'
  )};
  box-sizing: content-box;
`;

export const Circle = styled.div<CircleProps>`
  border-radius: 50%;
  background-color: ${({ theme, variant }) => (
    variant === NOTIFICATION_VARIANT.UNREAD ? theme.colors.primary : theme.colors.palette.systemGreyMedium
  )};
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ size }) => circleDimensions[size]}
`;

export const Container = styled.div<{ isInactive: boolean; shift: number; isSwipe: boolean, isRightSwipe: boolean }>`
  position: relative;
  display: flex;
  ${({ isInactive }) => isInactive && 'pointer-events: none; opacity: 0.5;'}
  margin-left: -${({ shift }) => `${shift}px;`}
  margin-right: ${({ isRightSwipe, shift }) => (isRightSwipe ? 0 : `${shift - SWIPE_THRESHOLD}px;`)};
  border-top: ${({ theme, isSwipe }) => (isSwipe ? `1px solid ${theme.colors.palette.systemGreyMedium}` : 'none')};
  box-shadow: ${({ isSwipe }) => (isSwipe ? '0px 2px 10px 0px rgba(54, 54, 54, 0.20);' : 'none')};
  box-sizing: content-box;
  user-select: none;

  width: calc(100vw + ${SWIPE_THRESHOLD}px);
  transition:
    margin-left 0.2s ease-in-out,
    margin-right 0.2s ease-in-out;

  & :previous:has(~ next) {
    border: none;

    .content-block {
      border: none;
    }
  }
`;

export const StyledIcon = styled(FontAwesomeIcon)<{ variant?: NOTIFICATION_VARIANT }>`
  color: ${({ theme, variant }) => (
    variant === NOTIFICATION_VARIANT.UNREAD ? theme.colors.$white : theme.colors.palette.systemGreyDark
  )};
`;

export const Drawer = styled.div<{ variant: NOTIFICATION_VARIANT }>`
  width: ${SWIPE_THRESHOLD}px;
  background-color: ${({ theme }) => theme.colors.primary};
  display: flex;
  margin-top: -16px;
  margin-right: 16px;
  font: 400 12px/14px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.sharedWhite};
  text-align: center;
  align-items: center;
  background-color: ${({ theme, variant }) => (
    variant === NOTIFICATION_VARIANT.UNREAD ? theme.colors.primary : `${theme.colors.palette.systemBlack}`)};
`;

export const DeleteDrawer = styled.div`
  width: ${SWIPE_THRESHOLD}px;
  display: flex;
  margin-top: -16px;
  margin-left: 16px;
  font: 400 12px/14px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.sharedWhite};
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => `${theme.colors.palette.systemPink}`};
`;

export const Swipeable = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const Visible = styled.div`
  width: calc(100vw - 32px);
  display: flex;
`;

export const TrashIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.palette.sharedWhite};
`;
