import React, { ChangeEvent, useCallback } from 'react';

import { SelectItem } from 'components/building-selector/consts/consts';
import {
  BuildingLabel,
  StyledDropdown,
} from 'components/building-selector/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedBuilding } from 'store/building/actions';
import {
  selectSelectedBuilding,
  selectUserBuildingsDropdownItems,
} from 'store/building/selectors';
import { getNotificationPreferencesList } from 'store/notification-preferences/actions';

export const BuildingSelector = (): JSX.Element => {
  const dispatch = useDispatch();
  const buildingsDropdownItems = useSelector(selectUserBuildingsDropdownItems);
  const selectedBuildingItem = useSelector(selectSelectedBuilding);

  const handleSelectBuilding = useCallback(
    (event: ChangeEvent<SelectItem>): void => {
      dispatch(setSelectedBuilding(event.target));
      dispatch(getNotificationPreferencesList.request({ buildingUuid: event.target.value }));
    },
    [dispatch],
  );

  if (buildingsDropdownItems.length === 1) {
    return <BuildingLabel data-testid="building-label">{selectedBuildingItem?.label}</BuildingLabel>;
  }

  return (
    <StyledDropdown
      data-testid="building-selector"
      name={selectedBuildingItem?.label}
      value={selectedBuildingItem?.value}
      label=""
      placeholder=""
      required={false}
      items={buildingsDropdownItems}
      onChange={handleSelectBuilding}
    />
  );
};
