import { NotificationsListContent } from 'components/notifications-list-content/notifications-list-content';
import { PageHeader } from 'components/page-header';
import { useNotificationsListPage } from 'pages/notifications-list-page/hooks/use-notifications-list-page.hook';
import { Container, StyledWrapper } from 'pages/notifications-list-page/styles';
import React from 'react';
import { useIntl } from 'react-intl';

export const NotificationsListPage = (): JSX.Element => {
  const intl = useIntl();
  const { pageHeaderRef, pageHeaderHeight } = useNotificationsListPage();

  return (
    <StyledWrapper pageHeaderHeight={pageHeaderHeight}>
      <PageHeader
        pageHeaderRef={pageHeaderRef}
        title={intl.formatMessage({ id: 'notifications_list_title' })}
      />
      <Container data-testid="notifications-list-page">
        <NotificationsListContent />
      </Container>
    </StyledWrapper>
  );
};
