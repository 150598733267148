import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 235px;
  align-items: center;
  margin-top: 50px;
`;

export const StyledIcon = styled(FontAwesomeIcon)`
  width: 28px;
  height: 28px;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.$sharedGreyDark};
`;

export const Title = styled.div`
  font: 500 16px/22px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
`;

export const Body = styled.div`
  font: 400 14px ${({ theme }) => theme.fonts.join()};
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  text-align: center;
`;
