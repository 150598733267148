import { externalLogin } from 'store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useSearchParams } from './use-search-params.hook';
import { currentUser, userLoading } from 'store/user/selectors';
import { configSelector } from 'store/config/selectors';

type AuthParams = { authToken?: string; apiUrl?: string; buildingUuid?: string };

export const useExternalLogin = (): boolean => {
  const dispatch = useDispatch();
  const { authToken, apiUrl, buildingUuid } = useSearchParams<AuthParams>();
  const isUserLoading = useSelector(userLoading);
  const user = useSelector(currentUser);
  const {
    authToken: configAuthToken,
    apiUrl: configApiUrl,
    buildingUuid: configBuildingUuid,
  } = useSelector(configSelector) || {};

  useEffect((): void => {
    if (!user) {
      dispatch(
        externalLogin.request({
          authToken: authToken ?? configAuthToken ?? null,
          apiUrl: apiUrl ?? configApiUrl ?? null,
          buildingUuid: buildingUuid ?? configBuildingUuid ?? null,
        }),
      );
    }
  }, [apiUrl, authToken, dispatch, user, buildingUuid, configAuthToken, configApiUrl, configBuildingUuid]);

  return isUserLoading;
};
