import { LinkTypeEnum } from '@hqo/hqo-miniapp-client-sdk/dist/sdk/types/sdk-message.types';
import { ClickNotificationDO } from 'components/notification-component';
import { IN_APP_CONTENT } from 'shared/consts/in-app-content';

export const mapNotificationToNavigationObject = ({
  link, buildingUuid, contentUuid,
}: Pick<ClickNotificationDO, 'link' | 'buildingUuid' | 'contentUuid'>) => ({
  ...(!link && { value: IN_APP_CONTENT.CONTENT }),
  type: link ? LinkTypeEnum.WEBVIEW : LinkTypeEnum.NATIVE_SCREEN,
  options: {
    url: link,
    building_uuid: buildingUuid,
    uuid: contentUuid,
  },
});
