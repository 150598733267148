/* eslint-disable max-len */
import * as actions from './actions';

import { Observable, of } from 'rxjs';
import {
  catchError, filter, map, switchMap, withLatestFrom,
} from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { getErrorCode } from 'store/utils/get-error-code.util';

export const getNotificationPreferencesListEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.getNotificationPreferencesList.request)),
  withLatestFrom(state$),
  switchMap(
    ([{ payload }, state]): Observable<RootAction> => apiClient(state).getNotificationPreferencesList(payload).pipe(
      map((xhrPayload) => actions.getNotificationPreferencesList.success(xhrPayload.response)),
      catchError((error) => of(actions.getNotificationPreferencesList.failure({ error, errorCode: getErrorCode(error) }))),
    ),
  ),
);

export const updateNotificationPreferenceEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.updateNotificationPreference.request)),
  withLatestFrom(state$),
  switchMap(
    ([{ payload }, state]): Observable<RootAction> => apiClient(state).updateNotificationPreference(payload).pipe(
      map(() => actions.updateNotificationPreference.success(payload)),
      catchError((error) => of(actions.updateNotificationPreference.failure({ error, errorCode: getErrorCode(error) }))),
    ),
  ),
);
