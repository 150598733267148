import { FailureActionPayload } from 'store/errors/types';
import {
  DeleteNotification,
  GetNotificationList, NotificationItem, PaginatedNotificationListRo, PatchNotification,
} from 'store/notifications/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const getNotificationList = createAsyncAction(
  'GET_NOTIFICATION_LIST_REQUEST',
  'GET_NOTIFICATION_LIST_SUCCESS',
  'GET_NOTIFICATION_LIST_FAILURE',
)<GetNotificationList, PaginatedNotificationListRo, FailureActionPayload>();

export const getInfiniteNotifications = createAsyncAction(
  'GET_INFINITE_NOTIFICATIONS_REQUEST',
  'GET_INFINITE_NOTIFICATIONS_SUCCESS',
  'GET_INFINITE_NOTIFICATIONS_FAILURE',
)<GetNotificationList, Array<NotificationItem>, FailureActionPayload>();

export const patchNotificationAndRedirect = createAsyncAction(
  'PATCH_NOTIFICATION_REQUEST_AND_REDIRECT',
  'PATCH_NOTIFICATION_SUCCESS_AND_REDIRECT',
  'PATCH_NOTIFICATION_FAILURE_AND_REDIRECT',
)<PatchNotification, PatchNotification, FailureActionPayload>();

export const deleteNotificationForUser = createAsyncAction(
  'DELETE_NOTIFICATION_FOR_USER_REQUEST',
  'DELETE_NOTIFICATION_FOR_USER_SUCCESS',
  'DELETE_NOTIFICATION_FOR_USER_FAILURE',
)<DeleteNotification, DeleteNotification, FailureActionPayload>();

export const resetDeleteNotification = createAction('RESET_DELETE_NOTIFICATION')<void>();

export const resetPatchNotification = createAction('RESET_PATCH_NOTIFICATION')<void>();
