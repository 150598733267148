import React from 'react';
import {
  Body, StyledContainer, StyledIcon, Title,
} from './styles';
import { useIntl } from 'react-intl';
import { faBell } from '@fortawesome/pro-regular-svg-icons';

export const EmptyListPlaceholder = (): JSX.Element => {
  const intl = useIntl();

  return (
    <StyledContainer data-testid="empty-list-container">
      <StyledIcon data-testid="empty-list-bell-icon" icon={faBell} />
      <Title>{intl.formatMessage({ id: 'emptyListPlaceholderTitle' })}</Title>
      <Body>
        {intl.formatMessage({ id: 'emptyListPlaceholderBody' })}
      </Body>
    </StyledContainer>
  );
};
