import { Container } from 'hocs/shared-styles';
import React, { useCallback, useEffect, useState } from 'react';
import { Router } from 'components/router';
import { Spinner } from '@hqo/react-components-library/dist/atoms/spinner';
import { defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { useExternalLogin } from 'hooks/use-external-login.hook';
import { useSdkData } from 'hooks/use-sdk-data.hook';
import { useAnalytics } from 'hooks/use-analytics.hook';
import { ErrorToastMessage } from 'components/error-toast-message/error-toast-message';
import { selectShowToastError } from 'store/errors/selectors';
import { useSelector } from 'react-redux';

export const App: React.FC = (): JSX.Element => {
  useSdkData();
  useAnalytics();
  const [showToast, setShowToast] = useState(false);
  const showToastError = useSelector(selectShowToastError);

  useEffect(() => {
    if (showToastError) {
      setShowToast(true);
    } else {
      setShowToast(false);
    }
  }, [showToastError]);

  const closeToastMessage = useCallback(
    () => {
      setShowToast(false);
    },
    [setShowToast],
  );

  const shouldWaitForLogin = useExternalLogin();

  if (shouldWaitForLogin) {
    return (
      <Container isLoading>
        <Spinner size="10em" color={defaultTheme.colors.$greyLight} />
      </Container>
    );
  }

  return (
    <>
      <Router />
      {showToast && <ErrorToastMessage toggleToastVisibility={closeToastMessage} />}
    </>
  );
};
