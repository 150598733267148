import * as actions from './actions';

import {
  Observable, concat, of, EMPTY,
} from 'rxjs';
import {
  catchError, filter, map, switchMap, withLatestFrom,
} from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { getErrorCode } from 'store/utils/get-error-code.util';
import { redirectToContentAction } from 'store/miniapp-sdk/actions';
import {
  mapNotificationToNavigationObject,
} from 'components/notifications-list-content/utils/mapNotificationToNavigationObject';
import { PATCH_NOTIFICATION_STATUS } from './types';

export const getNotificationListEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.getNotificationList.request)),
  withLatestFrom(state$),
  switchMap(
    ([{ payload }, state]): Observable<RootAction> => apiClient(state).getNotificationList(payload.params).pipe(
      map((xhrPayload) => actions.getNotificationList.success(xhrPayload.response)),
      catchError((error) => of(actions.getNotificationList.failure({ error, errorCode: getErrorCode(error) }))),
    ),
  ),
);

export const getInfiniteNotificationsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.getInfiniteNotifications.request)),
  withLatestFrom(state$),
  switchMap(
    ([{ payload }, state]): Observable<RootAction> => apiClient(state).getNotificationList(payload.params).pipe(
      map((xhrPayload) => actions.getInfiniteNotifications.success(xhrPayload.response.data)),
      catchError((error) => of(actions.getInfiniteNotifications.failure({ error, errorCode: getErrorCode(error) }))),
    ),
  ),
);

export const deleteNotificationForUserEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.deleteNotificationForUser.request)),
  withLatestFrom(state$),
  switchMap(
    ([{ payload }, state]): Observable<RootAction> => apiClient(state).deleteNotification(payload).pipe(
      map(() => actions.deleteNotificationForUser.success(payload)),
      catchError((error) => of(actions.deleteNotificationForUser.failure({ error, errorCode: getErrorCode(error) }))),
    ),
  ),
);

export const patchNotificationAndRedirectEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) => action$.pipe(
  filter(isActionOf(actions.patchNotificationAndRedirect.request)),
  withLatestFrom(state$),
  switchMap(
    ([{ payload }, state]): Observable<RootAction> => {
      const { link, building_context, attachments } = state.notifications?.notifications.find(
        (item) => item.uuid === payload.notificationUuid,
      ) || {};
      const isPatchRequired = payload.drawerClick
        || (!payload.drawerClick && payload.inAppReadStatus === PATCH_NOTIFICATION_STATUS.READ);
      const redirectToContent = (link || attachments?.[0]?.attachment_uuid) && !payload.drawerClick;

      if (!(isPatchRequired || redirectToContent)) {
        return EMPTY;
      }

      if (!isPatchRequired && redirectToContent) {
        return of(
          redirectToContentAction({
            payload: mapNotificationToNavigationObject({
              link,
              buildingUuid: building_context,
              contentUuid: attachments[0]?.attachment_uuid,
            }),
          }),
        );
      }

      return apiClient(state)
        .patchNotification(payload)
        .pipe(
          switchMap(() => {
            const patchAction = isPatchRequired
              ? of(actions.patchNotificationAndRedirect.success(payload))
              : EMPTY;

            const sdkActionObservable = redirectToContent
              ? of(
                redirectToContentAction({
                  payload: mapNotificationToNavigationObject({
                    link,
                    buildingUuid: building_context,
                    contentUuid: attachments[0]?.attachment_uuid,
                  }),
                }),
              )
              : EMPTY;

            return concat(patchAction, sdkActionObservable);
          }),
          catchError(
            (error) => of(actions.patchNotificationAndRedirect.failure({ error, errorCode: getErrorCode(error) })),
          ),
        );
    },
  ),
);
