import { NotificationPreferenceRO } from 'store/notification-preferences/types';

import { ChannelEnum } from '@hqo/notifications-service-client/dist/notification-client/enums';

export const getNotificationPreferenceChannelField = (channel: ChannelEnum): keyof NotificationPreferenceRO => {
  switch (channel) {
  case ChannelEnum.EMAIL:
    return 'email_enabled';
  case ChannelEnum.PUSH:
    return 'push_enabled';
  case ChannelEnum.IN_APP:
    return 'in_app_enabled';
  default:
    throw new Error('Unknown channel');
  }
};
