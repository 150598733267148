import * as altBuildingsActions from 'store/alt-buildings/actions';
import * as themeActions from 'store/theme/actions';
import * as userActions from 'store/user/actions';
import * as buildingActions from 'store/building/actions';
import * as uiMetadataActions from 'store/ui-metadata/actions';
import * as notificationsActions from 'store/notifications/actions';

import {
  concatMap, filter, map, delay,
} from 'rxjs/operators';
import {
  hideNotificationErrorToast,
  showErrorNotification,
  showGenericErrorNotification,
  showNotificationErrorToast,
} from './actions';

import { ERROR_PATH, TOAST_MESSAGE_TIMEOUT } from 'shared/consts';
import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import { replace } from 'store/router/actions';
import { getNotificationList } from 'store/notifications/actions';
import { getNotificationPreferencesList, updateNotificationPreference } from 'store/notification-preferences/actions';

export const handleFailedRequests: Epic<RootAction, RootAction, RootState> = (action$) => action$.pipe(
  // Add failure actions to trigger error modal here and in the test file
  filter(isActionOf([])),
  map((action) => showErrorNotification({
    action: action.type,
    error: action.payload.error,
    errorCode: action.payload.errorCode,
  })),
);

export const handleGenericErrors: Epic<RootAction, RootAction, RootState> = (action$) => action$.pipe(
  // Add failure actions to trigger error page redirects here and in the test file
  filter(
    isActionOf([
      themeActions.getBuildingTheme.failure,
      userActions.externalLogin.failure,
      userActions.getCurrentUser.failure,
      altBuildingsActions.getAltBuildings.failure,
      buildingActions.getBuilding.failure,
      uiMetadataActions.fetchUiMetadata.failure,
      notificationsActions.getNotificationList.failure,
    ]),
  ),
  concatMap((action) => of(
    showGenericErrorNotification({
      action: action.type,
      error: action.payload.error,
      errorCode: action.payload.errorCode,
    }),
    replace(ERROR_PATH),
  )),
);

export const handleToastErrors: Epic<RootAction, RootAction, RootState> = (action$) => action$.pipe(
  filter(
    isActionOf([
      getNotificationList.failure,
      getNotificationPreferencesList.failure,
      updateNotificationPreference.failure,
      notificationsActions.patchNotificationAndRedirect.failure,
      notificationsActions.getInfiniteNotifications.failure,
    ]),
  ),
  map(() => showNotificationErrorToast()),
);

export const handleHideToastErrors: Epic<RootAction, RootAction, RootState> = (action$) => action$.pipe(
  filter(isActionOf([showNotificationErrorToast])),
  delay(TOAST_MESSAGE_TIMEOUT),
  concatMap(() => of(hideNotificationErrorToast())),
);
