import { ChannelEnum } from '@hqo/notifications-service-client/dist/notification-client/enums';

export interface GetNotificationPreferencesList {
  buildingUuid: string;
  offset?: number;
  limit?: number;
}

export interface PaginatedNotificationPreferencesListRo {
  data: Array<NotificationPreferenceRO>;
  total: number;
  count: number;
  offset: number;
}

export interface NotificationPreferenceRO {
  uuid: string;
  category_uuid: string;
  category_name: NotificationCategoryEnum;
  category_title: string;
  email_enabled: boolean;
  push_enabled: boolean;
  in_app_enabled: boolean;
  building_uuid: string;
  user_uuid: string;
}

export interface NotificationPreferencesState {
  notificationPreferences: Array<NotificationPreferenceRO>;
  totalNotificationPreferences: number;
  updateNotificationPreference: AsyncActionTypes;
  getNotificationPreferencesList: AsyncActionTypes;
}

export interface UpdateNotificationPreference {
  buildingUuid: string;
  notificationCategoryUuid: string;
  channel: ChannelEnum;
  enabled: boolean;
}

export enum NotificationCategoryEnum {
  CONTENT = 'CONTENT',
  INFORMATIONAL_UPDATES = 'INFORMATIONAL_UPDATES',
  PACKAGE_MANAGEMENT_INCOMING = 'PACKAGE_MANAGEMENT_INCOMING',
  PACKAGE_MANAGEMENT_OUTGOING = 'PACKAGE_MANAGEMENT_OUTGOING',
  RESOURCE_BOOKING_STATUS_UPDATES = 'RESOURCE_BOOKING_STATUS_UPDATES',
  VISITOR_REGISTRATION_VISIT_UPDATES = 'VISITOR_REGISTRATION_VISIT_UPDATES',
  VISITOR_REGISTRATION_VISITOR_KEY_UPDATES = 'VISITOR_REGISTRATION_VISITOR_KEY_UPDATES',
  SERVICE_REQUEST_UPDATES = 'SERVICE_REQUEST_UPDATES',
  PARKING_UPDATES = 'PARKING_UPDATES',
}
