import { hasToken as hasTokenSelector } from 'store/config/selectors';
import { useSelector } from 'react-redux';

import { User } from 'store/user/types';

import { currentUser } from 'store/user/selectors';

export const useCurrentUser = (): [User | null, boolean] => {
  const user = useSelector(currentUser);
  const hasToken = useSelector(hasTokenSelector);

  return [user, !user && hasToken];
};
