import {
  EMPTY, filter, switchMap, withLatestFrom,
} from 'rxjs';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { isActionOf } from 'typesafe-actions';
import { redirectToContentAction } from 'store/miniapp-sdk/actions';
import { HqoClientSdk } from '@hqo/hqo-miniapp-client-sdk';

export const sdkActionEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
) => action$.pipe(
  filter(isActionOf(redirectToContentAction)),
  withLatestFrom(state$),
  switchMap(([action, state]) => {
    const { app_uuid } = state.uiMetadata?.data || {};

    const sdkClient = new HqoClientSdk({
      develop: false,
      app_uuid,
      init_timeout: null,
    });

    sdkClient.redirectToContent(action.payload.payload);

    return EMPTY;
  }),
);
