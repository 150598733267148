import {
  DELETE_SWIPE_THRESHOLD,
  DELETE_SWIPE_THRESHOLD_DIVISOR,
  MOVEMENT_DIRECTION,
  SCROLL_MOVEMENT_THRESHOLD,
  SHIFT_DRAWER_OPENED,
  SWIPE_THRESHOLD,
  SWIPE_THRESHOLD_DIVISOR,
  TOUCH_MOVEMENT_THRESHOLD,
} from 'components/notification-component/constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_STATUSES } from 'shared/consts';
import { selectDeleteNotificationForUserStatus, selectPatchNotificationStatus } from 'store/notifications/selectors';
import { getFirstProperty } from 'utils/getFirstProperty';
import { resetDeleteNotification, resetPatchNotification } from 'store/notifications/actions';

export const useEventHandler = (parentRef: React.RefObject<HTMLDivElement>) => {
  const dispatch = useDispatch();
  const [startX, setStartX] = useState<number>(null);
  const [startY, setStartY] = useState<number>(null);
  const [drawerShift, setDrawerShift] = useState<number>(SWIPE_THRESHOLD);
  const [movementDirection, setMovementDirection] = useState<MOVEMENT_DIRECTION>(MOVEMENT_DIRECTION.UNMOVED);
  const patchNotificationStatus = useSelector(selectPatchNotificationStatus);
  const deleteNotificationStatus = useSelector(selectDeleteNotificationForUserStatus);
  const isRightSwipe = movementDirection === MOVEMENT_DIRECTION.RIGHT;

  useEffect(() => {
    if (
      patchNotificationStatus === ACTION_STATUSES.FULFILLED
      || deleteNotificationStatus === ACTION_STATUSES.FULFILLED
    ) {
      setDrawerShift(SWIPE_THRESHOLD);
      dispatch(resetDeleteNotification());
      dispatch(resetPatchNotification());
    }
  }, [setDrawerShift, patchNotificationStatus, deleteNotificationStatus]);

  const onTouchStart = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      setStartX((getFirstProperty(event.changedTouches) as unknown as Touch)?.clientX);
      setStartY((getFirstProperty(event.changedTouches) as unknown as Touch)?.clientY);
    },
    [],
  );

  const onTouchMove = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      if (startX !== null) {
        const currentParentRef = parentRef.current;
        const currentX = (getFirstProperty(event.changedTouches) as unknown as Touch)?.clientX;
        const currentY = (getFirstProperty(event.changedTouches) as unknown as Touch)?.clientY;
        const deltaX = currentX - startX;
        const deltaY = currentY - startY;

        const isNativeScroll = Math.abs(deltaY) > SCROLL_MOVEMENT_THRESHOLD
          || Math.abs(deltaX) < TOUCH_MOVEMENT_THRESHOLD;

        if (drawerShift === SHIFT_DRAWER_OPENED) {
          setMovementDirection(MOVEMENT_DIRECTION.LEFT);
        }

        if (drawerShift === DELETE_SWIPE_THRESHOLD) {
          setMovementDirection(MOVEMENT_DIRECTION.RIGHT);
        }

        if (
          drawerShift === SWIPE_THRESHOLD && isNativeScroll
        ) {
          return;
        }

        if (currentParentRef) {
          currentParentRef.style.overflowY = 'hidden';
        }

        if (movementDirection === MOVEMENT_DIRECTION.RIGHT) {
          if (deltaX > DELETE_SWIPE_THRESHOLD / DELETE_SWIPE_THRESHOLD_DIVISOR) {
            setDrawerShift(SWIPE_THRESHOLD);
          }
        }

        if (movementDirection === MOVEMENT_DIRECTION.LEFT) {
          if (
            deltaX < SHIFT_DRAWER_OPENED
            && Math.abs(deltaX) > SWIPE_THRESHOLD / SWIPE_THRESHOLD_DIVISOR
          ) {
            setDrawerShift(SWIPE_THRESHOLD);
          }
        }

        if (movementDirection === MOVEMENT_DIRECTION.UNMOVED) {
          if (deltaX < -SWIPE_THRESHOLD) {
            setMovementDirection(MOVEMENT_DIRECTION.RIGHT);
            setDrawerShift(DELETE_SWIPE_THRESHOLD);
          }

          if (deltaX > SWIPE_THRESHOLD / SWIPE_THRESHOLD_DIVISOR) {
            setDrawerShift(SHIFT_DRAWER_OPENED);
            setMovementDirection(MOVEMENT_DIRECTION.LEFT);
          }
        }
      }
    },
    [startX, startY, drawerShift, parentRef, movementDirection],
  );

  const onTouchEnd = useCallback(() => {
    const currentParentRef = parentRef.current;
    setStartX(null);
    setStartY(null);

    if (currentParentRef) {
      currentParentRef.style.overflowY = 'scroll';
    }

    if (movementDirection === MOVEMENT_DIRECTION.LEFT && drawerShift > SWIPE_THRESHOLD / SWIPE_THRESHOLD_DIVISOR) {
      setDrawerShift(SWIPE_THRESHOLD);
    }

    setMovementDirection(MOVEMENT_DIRECTION.UNMOVED);
  }, [drawerShift, parentRef, movementDirection]);

  const isDrawerShifted = drawerShift !== SWIPE_THRESHOLD || drawerShift > SWIPE_THRESHOLD;

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    drawerShift,
    isDrawerShifted,
    isRightSwipe,
  };
};
