import styled from 'styled-components';
import { ToastMessage } from '@hqo/react-components-library/dist/molecules/toast-message-v2';

export const ToastMessageContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: calc(100% - 32px);
  padding: 0 16px 16px 16px;
`;

export const StyledToastMessage = styled(ToastMessage)`
  background: ${({ theme }) => theme.colors.palette.chauncyRed};
  opacity: 1;
`;
