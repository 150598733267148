import { Pill } from '@hqo/react-components-library/dist/atoms/pill';
import { Switch } from '@hqo/react-components-library/dist/molecules/switch';
import {
  Container, Title, Subtitle, TextContainer,
} from 'components/switch-panel/styles';
import React from 'react';

interface SwitchPanelProps {
  title: string;
  subtitle: string;
  checked: boolean;
  dataTestId?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isRecommendedOn?: boolean;
  recommendedOnText?: string;
}

export const SwitchPanel = ({
  title, subtitle, checked, dataTestId, onChange, recommendedOnText, isRecommendedOn,
}: SwitchPanelProps): JSX.Element => (
  <Container data-testid={dataTestId || 'switch-panel-container'}>
    <TextContainer>
      <Title data-testid="switch-panel-title">{title}</Title>
      <Subtitle data-testid="switch-panel-subtitle">{subtitle}</Subtitle>
      {isRecommendedOn && <Pill variant="informational" data-testid="switch-panel-pill">{recommendedOnText}</Pill>}
    </TextContainer>
    <Switch checked={checked} onChange={onChange} />
  </Container>
);
