import { RootState } from '../reducer';
import { createSelector } from 'reselect';
import { NotificationsState } from 'store/notifications/types';

export const notificationsStateSelector = (state: RootState): NotificationsState => state.notifications;

export const selectNotifications = createSelector(
  notificationsStateSelector,
  (notificationsState) => notificationsState.notifications || [],
);

export const selectGetNotificationListStatus = createSelector(
  notificationsStateSelector,
  (notificationsState) => notificationsState.getNotificationList.status,
);

export const selectTotalNotifications = createSelector(
  notificationsStateSelector,
  (notificationsState) => notificationsState.totalNotifications || 0,
);

export const selectGetInfiniteNotificationsStatus = createSelector(
  notificationsStateSelector,
  (notificationsState) => notificationsState.getInfiniteNotifications.status,
);

export const selectPatchNotificationStatus = createSelector(
  notificationsStateSelector,
  (notificationsState) => notificationsState.patchNotification?.status,
);

export const selectPatchNotificationUuid = createSelector(
  notificationsStateSelector,
  (notificationsState) => notificationsState.patchNotificationUuid,
);

export const selectDeleteNotificationUuid = createSelector(
  notificationsStateSelector,
  (notificationsState) => notificationsState.deleteNotificationUuid,
);

export const selectDeleteNotificationForUserStatus = createSelector(
  notificationsStateSelector,
  (notificationsState) => notificationsState.deleteNotificationForUser?.status,
);

export const selectDeleteNotificationForUserError = createSelector(
  notificationsStateSelector,
  (notificationsState) => notificationsState.deleteNotificationForUser?.error,
);
