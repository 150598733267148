import { useCallback, useEffect } from 'react';
import { ChannelEnum } from '@hqo/notifications-service-client/dist/notification-client/enums/channel.enum';
import { useSelector, useDispatch } from 'react-redux';
import { ACTION_STATUSES, NOTIFICATION_LIST_LIMIT } from 'shared/consts';
import { getInfiniteNotifications } from 'store/notifications/actions';
import { selectGetInfiniteNotificationsStatus, selectTotalNotifications } from 'store/notifications/selectors';

interface UseInfiniteScrollProps {
  notificationsListContentContainerRef: React.RefObject<HTMLDivElement>;
  totalDisplayedNotifications: number;
}

interface UseInfiniteScrollResponse {
  isInfiniteScrolling: boolean;
}

export const useInfiniteScroll = ({
  notificationsListContentContainerRef,
  totalDisplayedNotifications,
}: UseInfiniteScrollProps): UseInfiniteScrollResponse => {
  const dispatch = useDispatch();
  const isInfiniteScrolling = useSelector(selectGetInfiniteNotificationsStatus) === ACTION_STATUSES.PENDING;
  const totalNotifications = useSelector(selectTotalNotifications);
  const initiateInfiniteScrollThreshold = 20; // px
  const containerRef = notificationsListContentContainerRef?.current;

  const onScroll = useCallback(() => {
    if (isInfiniteScrolling) {
      return;
    }
    const scrollTop = containerRef?.scrollTop;
    const offsetHeight = containerRef?.offsetHeight;
    const scrollHeight = containerRef?.scrollHeight;

    const isScrollingToBottom = scrollTop + offsetHeight >= scrollHeight - initiateInfiniteScrollThreshold;

    if (isScrollingToBottom && totalDisplayedNotifications < totalNotifications) {
      dispatch(
        getInfiniteNotifications.request({
          params: {
            channels: [ChannelEnum.IN_APP],
            limit: NOTIFICATION_LIST_LIMIT,
            offset: totalDisplayedNotifications,
          },
        }),
      );
    }
  }, [isInfiniteScrolling, containerRef, totalDisplayedNotifications, totalNotifications, dispatch]);

  useEffect(() => {
    containerRef?.addEventListener('scroll', onScroll);

    return () => {
      containerRef?.removeEventListener('scroll', onScroll);
    };
  }, [containerRef, onScroll]);

  return { isInfiniteScrolling };
};
