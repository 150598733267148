/* eslint-disable max-len */
import { GetNotificationListDto } from '@hqo/notifications-service-client/dist/notification-client/dto/get-notification-list.dto';
import { PaginationResultsRo } from '@hqo/notifications-service-client/dist/notification-client/dto/response/pagination-ro.dto';
import { NotificationResponse } from '@hqo/notifications-service-client/dist/notification-client/interfaces/notification-response.interface';

export enum PATCH_NOTIFICATION_STATUS {
  READ = 'READ',
  UNREAD = 'UNREAD',
}

export const ALL_NOTIFICATIONS = 'all';

export enum AttachmentType {
  CONTENT = 'CONTENT',
  MODULE = 'MODULE',
  URI = 'URI',
}

export interface GetNotificationList {
  params: GetNotificationListDto;
}

export interface PatchNotification {
  notificationUuid: string;
  inAppReadStatus: PATCH_NOTIFICATION_STATUS
  drawerClick?: boolean
}

export interface DeleteNotification {
  notificationUuid: string;
  buildingUuid: string;
}

export interface NotificationsState {
  notifications: Array<NotificationItem>;
  totalNotifications: number;
  patchNotificationUuid: string;
  deleteNotificationUuid: string;
  getNotificationList: AsyncActionTypes;
  getInfiniteNotifications: AsyncActionTypes;
  patchNotification: AsyncActionTypes;
  deleteNotificationForUser: AsyncActionTypes;
}

export interface NotificationItem extends NotificationResponse {
  building_context: string;
  attachments?: Array<Attachment>;
}

export interface Attachment {
  attachment_type: AttachmentType;
  attachment_uuid?: string;
  attachment_params?: Record<string, unknown>;
  attachment_uri?: string;
}

export interface PaginatedNotificationListRo extends PaginationResultsRo {
  data: Array<NotificationItem>;
}

export enum NotificationCategoryEnum {
  CONTENT = 'CONTENT',
  INFORMATIONAL_UPDATES = 'INFORMATIONAL_UPDATES',
  PACKAGE_MANAGEMENT_INCOMING = 'PACKAGE_MANAGEMENT_INCOMING',
  PACKAGE_MANAGEMENT_OUTGOING = 'PACKAGE_MANAGEMENT_OUTGOING',
  RESOURCE_BOOKING_STATUS_UPDATES = 'RESOURCE_BOOKING_STATUS_UPDATES',
  VISITOR_REGISTRATION_VISIT_UPDATES = 'VISITOR_REGISTRATION_VISIT_UPDATES',
  VISITOR_REGISTRATION_VISITOR_KEY_UPDATES = 'VISITOR_REGISTRATION_VISITOR_KEY_UPDATES',
  SERVICE_REQUEST_UPDATES = 'SERVICE_REQUEST_UPDATES',
  PARKING_UPDATES = 'PARKING_UPDATES',
}
