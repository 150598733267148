import { NOTIFICATION_VARIANT } from 'components/notification-component';

export const getNotificationVariant = (
  notification: { in_app_read_at: Date },
  patchNotificationUuid: string,
  deleteNotificationUuid: string,
  notificationUuid: string,
): NOTIFICATION_VARIANT => {
  if (patchNotificationUuid === notificationUuid || deleteNotificationUuid === notificationUuid) {
    return NOTIFICATION_VARIANT.INACTIVE;
  }

  return notification.in_app_read_at ? NOTIFICATION_VARIANT.READ : NOTIFICATION_VARIANT.UNREAD;
};
