import { SelectTw } from '@hqo/react-components-library/dist/atoms/select';
import styled from 'styled-components';

export const StyledDropdown = styled(SelectTw)`
  margin-bottom: 24px;
  width: fit-content;

  .MuiInputBase-root {
    border: none;
    color: ${({ theme }) => theme.colors.palette.systemBlack};
    height: fit-content;
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }

  label {
    font: 600 18px/20px ${({ theme }) => theme.fonts.join()};
    margin: 0;
  }

  .field-label {
    display: none;
  }
`;

export const BuildingLabel = styled.div`
  font: 600 18px/20px ${({ theme }) => theme.fonts.join()};
  margin: 25px 0 30px 0;
  width: fit-content;
  height: fit-content;
`;
