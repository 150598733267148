import { AppThemeProvider, defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'components/intl-provider';
import { Provider } from 'react-redux';
import React from 'react';
import { RootState } from 'store/reducer';
import { createStore } from 'redux';

interface TestWrapperProps {
  children?: React.ReactNode;
  state?: Partial<RootState>;
}
export const TestWrapper: React.FC<TestWrapperProps> = ({ children, state }: TestWrapperProps): JSX.Element => {
  const store = createStore(() => state);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <IntlProvider>
          <AppThemeProvider theme={defaultTheme}>{children}</AppThemeProvider>
        </IntlProvider>
      </BrowserRouter>
    </Provider>
  );
};
