import { useEffect } from 'react';

import { useMiniappSdk } from 'components/miniapp-sdk-provider/miniapp-sdk.hooks';
import { useDispatch, useSelector } from 'react-redux';
import { ACTION_STATUSES } from 'shared/consts';
import { getUserBuildings, setSelectedBuilding } from 'store/building/actions';
import {
  selectBuilding,
  selectGetUserBuildingsStatus,
  selectSelectedBuilding,
} from 'store/building/selectors';

import { HEADER_BUTTON_MODE } from '@hqo/hqo-miniapp-client-sdk/dist/sdk/types/sdk-message.types';

export const useSettingsPage = () => {
  const dispatch = useDispatch();
  const client = useMiniappSdk();
  const getUserBuildingsStatus = useSelector(selectGetUserBuildingsStatus);
  const selectedBuilding = useSelector(selectSelectedBuilding);
  const originalBuilding = useSelector(selectBuilding);

  useEffect(() => {
    client?.header.setHeader('', HEADER_BUTTON_MODE.HISTORY);
  }, []);

  useEffect(() => {
    dispatch(getUserBuildings.request());
  }, [dispatch]);

  useEffect(() => {
    if (getUserBuildingsStatus === ACTION_STATUSES.PENDING && selectedBuilding !== null) {
      dispatch(setSelectedBuilding(null));
    }
    if (getUserBuildingsStatus !== ACTION_STATUSES.PENDING && selectedBuilding === null && originalBuilding !== null) {
      dispatch(setSelectedBuilding({
        label: originalBuilding.name,
        value: originalBuilding.uuid,
      }));
    }
  }, [getUserBuildingsStatus, selectedBuilding, originalBuilding, dispatch]);
};
