import {
  Circle,
  Container,
  ContentBlock,
  ContentContainer,
  Message,
  MessageInfo,
  StyledIcon,
  Title,
  NotificationIconBlock,
  Drawer,
  Swipeable,
  Visible,
  DeleteDrawer,
  TrashIcon,
} from './styles';
import React, { useCallback } from 'react';
import { SIZE_VARIANTS, TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { NOTIFICATION_VARIANT, categoryToVerticalMap } from './constants';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { NotificationCategoryEnum, PATCH_NOTIFICATION_STATUS } from 'store/notifications/types';
import { useIntl } from 'react-intl';
import { useEventHandler } from 'components/notification-component/hooks/useEventHandler.hook';
import { track } from '@hqo/web-tracking';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { deleteNotificationForUser } from 'store/notifications/actions';

export interface ClickNotificationDO {
  notificationUuid: string;
  notificationStatus: PATCH_NOTIFICATION_STATUS;
  buildingUuid?: string;
  link?: string;
  contentUuid?: string;
  drawerClick?: boolean;
}

export interface NotificationComponentProps {
  notificationUuid: string;
  icon: string;
  title: string;
  message: string;
  messageInfo: string;
  onClick: ({ notificationUuid, notificationStatus }: ClickNotificationDO) => void;
  variant: NOTIFICATION_VARIANT;
  withRedirectAction: boolean;
  parentRef: React.RefObject<HTMLDivElement>;
  buildingUuid: string;
  notificationCategory: string;
  link: string;
}

export const NotificationComponent: React.FC<NotificationComponentProps> = ({
  notificationUuid,
  icon,
  message,
  messageInfo,
  title,
  onClick,
  variant,
  withRedirectAction,
  parentRef,
  buildingUuid,
  notificationCategory,
  link,
}: NotificationComponentProps): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const notificationStatus = variant === NOTIFICATION_VARIANT.READ
    ? PATCH_NOTIFICATION_STATUS.UNREAD : PATCH_NOTIFICATION_STATUS.READ;
  const {
    onTouchStart, onTouchMove, onTouchEnd, drawerShift, isDrawerShifted, isRightSwipe,
  } = useEventHandler(parentRef);
  const hasCursorPointer = notificationStatus === PATCH_NOTIFICATION_STATUS.READ || withRedirectAction;

  const onClickHandler = useCallback(() => {
    onClick?.({
      notificationUuid,
      notificationStatus,
    });
    track(
      TRACK_EVENT_NAMES.NOTIF_LIST_CLICK,
      {
        type: TRACK_EVENT_TYPES.ACTION,
        vertical: categoryToVerticalMap.get(notificationCategory as NotificationCategoryEnum),
        building: buildingUuid,
        notification_id: notificationUuid,
        link_to: link,
      },
      { sendToHqoTracking: true },
    );
  }, [notificationUuid, onClick, link, buildingUuid, notificationCategory, notificationStatus]);

  const onDrawerClick = useCallback(() => {
    onClick?.({
      notificationUuid,
      notificationStatus,
      drawerClick: true,
    });
    if (variant !== NOTIFICATION_VARIANT.INACTIVE) {
      track(
        TRACK_EVENT_NAMES.NOTIF_LIST_READ_STATUS_TOGGLE,
        {
          type: TRACK_EVENT_TYPES.ACTION,
          notification_id: notificationUuid,
          read_status: variant,
        },
        { sendToHqoTracking: true },
      );
    }
  }, [notificationStatus, notificationUuid, onClick, variant]);

  const onDeleteDrawerClick = useCallback(() => {
    dispatch(deleteNotificationForUser.request({ notificationUuid, buildingUuid }));
  }, [notificationUuid, buildingUuid]);

  return (
    <Container
      data-testid="notification-component"
      isInactive={variant === NOTIFICATION_VARIANT.INACTIVE}
      onTouchEnd={onTouchEnd}
      onTouchMove={onTouchMove}
      onTouchStart={onTouchStart}
      shift={drawerShift}
      isSwipe={isDrawerShifted}
      isRightSwipe={isRightSwipe}
    >
      <Swipeable>
        <Drawer onClick={onDrawerClick} variant={variant} data-testid="drawer">
          {intl.formatMessage({ id: 'markAsRead' }, { variant })}
        </Drawer>
        <Visible data-testid="visible-content">
          <NotificationIconBlock>
            <Circle variant={variant} size={SIZE_VARIANTS.NORMAL}>
              <StyledIcon
                data-testid="notification-icon"
                variant={variant}
                icon={['far', icon] as IconProp}
                size="sm"
              />
            </Circle>
          </NotificationIconBlock>
          <ContentBlock
            hasCursorPointer={hasCursorPointer}
            onClick={onClickHandler}
            data-testid="content-block"
            withBorder={isDrawerShifted}
            className="content-block"
          >
            <ContentContainer>
              <Title variant={variant}>{title}</Title>
              <Message>{message}</Message>
              <MessageInfo>{messageInfo}</MessageInfo>
            </ContentContainer>
            {withRedirectAction && (
              <Circle size={SIZE_VARIANTS.SMALL}>
                <StyledIcon icon={faChevronRight} size="xs" />
              </Circle>
            )}
          </ContentBlock>
        </Visible>
        <DeleteDrawer onClick={onDeleteDrawerClick} data-testid="delete-drawer">
          <TrashIcon icon={faTrash} />
        </DeleteDrawer>
      </Swipeable>
    </Container>
  );
};
