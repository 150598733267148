import styled from 'styled-components';

export const SkeletonBlock = styled.div<{ boneSizePx: number }>`
  width: 100%;
  height: ${({ boneSizePx }) => boneSizePx}px;
  margin-bottom: 16px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.palette.systemGreyMedium};
`;

export const SkeletonContainer = styled.div<{ withWrapper: boolean }>`
  width: ${({ withWrapper }) => (withWrapper ? '100%;' : 'calc(100% - 32px);')}
  margin: 0 auto;
`;
