import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px 8px 12px;
  border: 1px solid ${({ theme }) => theme.colors.palette.systemGreyMedium};
  border-radius: 4px;
`;

export const Title = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 8px;
  color: ${({ theme }) => theme.colors.fontColor};
  font: 500 16px/20px ${({ theme }) => theme.fonts.join()};
`;

export const Subtitle = styled(Title)`
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  font: 400 12px/20px ${({ theme }) => theme.fonts.join()};
  white-space: pre-line;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: calc(100% - 40px);
  gap: 4px;
`;
