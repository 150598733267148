/* eslint-disable max-len */
import qs from 'qs';
import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { VISITOR_REGISTRATION } from 'shared/consts';
import { OwnerParams } from 'shared/types';
import { AltBuilding } from 'store/alt-buildings/types';
import { NOTIFICATION_PREFERENCE_DEFAULT_LIMIT, NOTIFICATION_PREFERENCE_DEFAULT_OFFSET } from 'store/api-client/constants';
import { BuildingResponse, GetUserBuildingsResponse } from 'store/building/types';
import { GetNotificationPreferencesList, PaginatedNotificationPreferencesListRo } from 'store/notification-preferences/types';
import { DeleteNotification, PaginatedNotificationListRo, PatchNotification } from 'store/notifications/types';
import { BuildingTheme } from 'store/theme/types';
import { UiMetadata } from 'store/ui-metadata/types';
import { CurrentUserResponse } from 'store/user/types';
import { v4 } from 'uuid';

import { GetNotificationListDto } from '@hqo/notifications-service-client/dist/notification-client/dto/get-notification-list.dto';
import { ChannelEnum } from '@hqo/notifications-service-client/dist/notification-client/enums';

export class ApiClient {
  constructor(
    private baseUrl: string,
    private authToken?: string | null,
    private altBuildingUuid?: string | null,
    private locale?: string | null,
  ) {
    // do nothing
  }

  private get DefaultHeaders(): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      Authorization: this.authToken,
      'hqo-trace-id': v4(),
      locale: this.locale,
      ...(this.altBuildingUuid ? { 'alt-building-uuid': this.altBuildingUuid } : {}),
    };
  }

  getCurrentUser(): Observable<AjaxResponse<CurrentUserResponse>> {
    return ajax.get(`${this.baseUrl}/api/auth/current`, this.DefaultHeaders);
  }

  getBuildingTheme(buildingUuid: string): Observable<AjaxResponse<BuildingTheme>> {
    return ajax.get(`${this.baseUrl}/api/buildings/${buildingUuid}/theme`, this.DefaultHeaders);
  }

  getBuilding(buildingUuid: string): Observable<AjaxResponse<BuildingResponse>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/buildings/${buildingUuid}?include=languages`, this.DefaultHeaders);
  }

  getAltBuildings(buildingUuid: string): Observable<AjaxResponse<AltBuilding[]>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/buildings/alt_buildings/${buildingUuid}`, this.DefaultHeaders);
  }

  getUserBuildings(): Observable<AjaxResponse<GetUserBuildingsResponse>> {
    return ajax.get(`${this.baseUrl}/api/tenant/v1/users/current/buildings`, this.DefaultHeaders);
  }

  fetchUiMetadata({ ownerType, ownerId }: OwnerParams): Observable<AjaxResponse<UiMetadata>> {
    return ajax.get(
      `${this.baseUrl}/api/verticals/${VISITOR_REGISTRATION}/${ownerType.toUpperCase()}/${ownerId}/ui-metadata`,
      this.DefaultHeaders,
    );
  }

  getNotificationList(params: GetNotificationListDto): Observable<AjaxResponse<PaginatedNotificationListRo>> {
    const queryString = qs.stringify(params, { arrayFormat: 'comma', skipNulls: true, addQueryPrefix: true });

    return ajax.get(`${this.baseUrl}/api/tenant/v1/notifications${queryString}`, this.DefaultHeaders);
  }

  getNotificationPreferencesList({
    buildingUuid,
    offset = NOTIFICATION_PREFERENCE_DEFAULT_OFFSET,
    limit = NOTIFICATION_PREFERENCE_DEFAULT_LIMIT,
  }: GetNotificationPreferencesList): Observable<AjaxResponse<PaginatedNotificationPreferencesListRo>> {
    const queryString = qs.stringify({ offset, limit }, { arrayFormat: 'comma', skipNulls: true, addQueryPrefix: true });

    return ajax.get(`${this.baseUrl}/api/tenant/v1/BUILDING/${buildingUuid}/notification-preferences${queryString}`, this.DefaultHeaders);
  }

  updateNotificationPreference({
    buildingUuid,
    notificationCategoryUuid,
    channel,
    enabled,
  }: {
    buildingUuid: string;
    notificationCategoryUuid: string;
    channel: ChannelEnum;
    enabled: boolean;
  }): Observable<AjaxResponse<void>> {
    return ajax.post(`${this.baseUrl}/api/tenant/v1/BUILDING/${buildingUuid}/notification-preferences`, {
      notificationCategoryUuid,
      channel,
      enabled,
    }, this.DefaultHeaders);
  }

  patchNotification({ notificationUuid, inAppReadStatus }: PatchNotification): Observable<AjaxResponse<void>> {
    return ajax.patch(
      `${this.baseUrl}/api/tenant/v1/notifications/${notificationUuid}/read-status`,
      { in_app_read_status: inAppReadStatus },
      this.DefaultHeaders,
    );
  }

  deleteNotification({ notificationUuid, buildingUuid }: DeleteNotification): Observable<AjaxResponse<void>> {
    return ajax.delete(
      `${this.baseUrl}/api/tenant/v1/notifications/${notificationUuid}`,
      { ...this.DefaultHeaders, 'HqO-Building-UUID': buildingUuid },
    );
  }
}
