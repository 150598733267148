export const LAUNCH_DARKLY_ANONYMOUS_EMAIL = 'anonymous@hqo.co';
export const BUILDING = 'BUILDING';

export const APP_UUID = 'notification_center';

export const NOTIFICATION_LIST_LIMIT = 15;

export const DEFAULT_NOTIFICATION_ICON = 'FA:fa-bell';

export enum TRACK_EVENT_NAMES {
  NOTIF_LIST_IMPRESSION = 'notif_list_impression',
  NOTIF_LIST_PREFERENCES_CLICK = 'notif_list_preferences_click',
  NOTIF_LIST_READ_STATUS_TOGGLE = 'notif_list_read_status_toggle',
  NOTIF_LIST_CLICK = 'notif_list_click',
  NOTIF_PREFERENCES_PUSH_SETTING_CLICK = 'notif_preferences_push_setting_click',
}

export enum TRACK_EVENT_TYPES {
  ACTION = 'action',
  IMPRESSION = 'impression',
}

export const TOAST_MESSAGE_TIMEOUT = 2_000;
