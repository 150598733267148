import { SkeletonBlock, SkeletonContainer } from 'components/skeleton/styles';
import React from 'react';

export interface SkeletonProps {
  boneSizePx?: number;
  boneNumber?: number;
  withWrapper?: boolean;
}

export const Skeleton = ({ boneSizePx = 102, boneNumber = 2, withWrapper }: SkeletonProps) => {
  const skeletonPlaceholder = Array.from(Array(boneNumber).keys());

  return (
    <SkeletonContainer withWrapper={withWrapper}>
      {skeletonPlaceholder.map((bone) => (
        <SkeletonBlock key={bone} data-testid="skeleton" boneSizePx={boneSizePx} />
      ))}
    </SkeletonContainer>
  );
};
