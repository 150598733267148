import React, { useCallback } from 'react';

import { SwitchPanel } from 'components/switch-panel';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedBuilding } from 'store/building/selectors';
import { updateNotificationPreference } from 'store/notification-preferences/actions';
import { NotificationPreferenceRO } from 'store/notification-preferences/types';

import { ChannelEnum } from '@hqo/notifications-service-client/dist/notification-client/enums';
import { RECOMMENDED_ON_CATEGORIES }
  from 'pages/settings-page/notification-preference/notification-preferences.constants';
import { track } from '@hqo/web-tracking';
import { TRACK_EVENT_NAMES, TRACK_EVENT_TYPES } from 'shared/consts';

export interface NotificationPreferenceInterface {
  notificationPreference: NotificationPreferenceRO;
}

export const NotificationPreference = ({ notificationPreference }: NotificationPreferenceInterface) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const selectedBuildingItem = useSelector(selectSelectedBuilding);

  const onChange = useCallback(() => {
    if (selectedBuildingItem) {
      dispatch(
        updateNotificationPreference.request({
          notificationCategoryUuid: notificationPreference.category_uuid,
          channel: ChannelEnum.PUSH,
          enabled: !notificationPreference.push_enabled,
          buildingUuid: selectedBuildingItem.value,
        }),
      );
      track(
        TRACK_EVENT_NAMES.NOTIF_PREFERENCES_PUSH_SETTING_CLICK,
        {
          type: TRACK_EVENT_TYPES.ACTION,
          notification_category_uuid: notificationPreference.category_uuid,
          channel: ChannelEnum.PUSH,
          push_setting_status: !notificationPreference.push_enabled ? 'on' : 'off',
        },
        { sendToHqoTracking: true },
      );
    }
  }, [dispatch, notificationPreference.category_uuid, notificationPreference.push_enabled, selectedBuildingItem]);
  const categoryNameLowerCase = notificationPreference.category_name.toLowerCase();

  return (
    <SwitchPanel
      key={notificationPreference.uuid}
      title={notificationPreference.category_title} // this value comes translated from the backend already
      subtitle={intl.formatMessage({
        id: `notification_preferences.${categoryNameLowerCase}.subtitle`,
      })}
      isRecommendedOn={RECOMMENDED_ON_CATEGORIES.includes(notificationPreference.category_name)}
      recommendedOnText={intl.formatMessage({
        id: 'notification_preferences.recommended_on',
      })}
      checked={notificationPreference.push_enabled}
      onChange={onChange}
    />
  );
};
